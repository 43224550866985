import fetchAxios from '../../middlewares/axios';
import {
  LIST_TEAMBUILDING,
  LOAD_TEAMBUILDING,
  SET_TEAMBUILDINGS,
  SET_TEAMBUILDING,
  UPDATE_TEAMBUILDING,
  SAVE_TEAMBUILDING,
  REMOVE_TEAMBUILDING,
  LIST_ACTIVITIES,
  SET_ACTIVITIES,
  LOAD_ACTIVITY,
  SET_ACTIVITY,
  SAVE_ACTIVITY,
  REMOVE_ACTIVITY,
  UPDATE_ACTIVITY,
  SET_LOCATION,
  UPDATE_LOCATION,
  REMOVE_LOCATION,
  SAVE_LOCATION,
  LOAD_LOCATION,
  LIST_LOCATIONS,
  SET_LOCATIONS,
  LIST_ACTIVITY_CATEGORIES,
  SET_ACTIVITY_CATEGORIES,
  LIST_ACTIVITY_VALUES,
  SET_ACTIVITY_VALUES,
  SAVE_ACTIVITY_VALUE,
  SAVE_ACTIVITY_CATEGORY,
  LIST_POSSIBILITIES,
  SET_POSSIBILITIES,
  SAVE_POSSIBILITY_VALUE,
} from './types';
import { ROOT_URL_PROTECTED } from '../../../config/axios';

export const listTeambuilding = () => {
  return (dispatch) => {
    dispatch({ type: LIST_TEAMBUILDING, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/teambuilding/`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.teambuilding
          ) {
            dispatch({
              type: SET_TEAMBUILDINGS,
              teambuilding: response.data.teambuilding,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_TEAMBUILDING });
          reject({ error: e });
        });
    });
  };
};

export const showTeambuilding = (id) => {
  return (dispatch) => {
    dispatch({ type: LOAD_TEAMBUILDING, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/teambuilding/detail/${id}`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.teambuilding
          ) {
            dispatch({
              type: SET_TEAMBUILDING,
              teambuildingDetail: response.data.teambuilding,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LOAD_TEAMBUILDING });
          reject({ error: e });
        });
    });
  };
};

export const saveTeambuilding = () => {
  return (dispatch, getState) => {
    const { teambuilding } = getState();
    const { longDescriptionState, ...rest } = teambuilding.teambuildingDetail;
    dispatch({ type: SAVE_TEAMBUILDING, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/teambuilding/update`,
        {
          teambuilding: rest,
        },
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.teambuilding
          ) {
            dispatch({
              type: SET_TEAMBUILDING,
              teambuildingDetail: response.data.teambuilding,
            });
            resolve(response.data);
          } else {
            reject('SAVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: SAVE_TEAMBUILDING });
          reject({ error: e });
        });
    });
  };
};

export const removeTeambuilding = (id) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_TEAMBUILDING, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/teambuilding/remove`,
        {
          id,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: REMOVE_TEAMBUILDING,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: REMOVE_TEAMBUILDING });
          reject({ error: e });
        });
    });
  };
};

export const updateTeambuilding = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TEAMBUILDING,
      field,
      value,
    });
  };
};

export const resetTeambuilding = (copy) => {
  return (dispatch) => {
    dispatch({
      type: SET_TEAMBUILDING,
      teambuildingDetail: copy || {},
    });
  };
};

export const listTeambuildingPossibilities = () => {
  return (dispatch) => {
    dispatch({ type: LIST_POSSIBILITIES, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/teambuilding/possibilities`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.possibilities
          ) {
            dispatch({
              type: SET_POSSIBILITIES,
              possibilities: response.data.possibilities,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_POSSIBILITIES });
          reject({ error: e });
        });
    });
  };
};

export const listActivities = () => {
  return (dispatch) => {
    dispatch({ type: LIST_ACTIVITIES, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/teambuilding/activities`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.activities
          ) {
            dispatch({
              type: SET_ACTIVITIES,
              activities: response.data.activities,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_ACTIVITIES });
          reject({ error: e });
        });
    });
  };
};

export const listActivityCategories = () => {
  return (dispatch) => {
    dispatch({ type: LIST_ACTIVITY_CATEGORIES, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/teambuilding/activities/categories`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.categories
          ) {
            dispatch({
              type: SET_ACTIVITY_CATEGORIES,
              activityCategories: response.data.categories,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_ACTIVITY_CATEGORIES });
          reject({ error: e });
        });
    });
  };
};

export const listActivityValues = () => {
  return (dispatch) => {
    dispatch({ type: LIST_ACTIVITY_VALUES, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/teambuilding/activities/values`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.values
          ) {
            dispatch({
              type: SET_ACTIVITY_VALUES,
              activityValues: response.data.values,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_ACTIVITY_VALUES });
          reject({ error: e });
        });
    });
  };
};

export const addTeambuildingPossibility = (possibility) => {
  return (dispatch, getState) => {
    dispatch({ type: SAVE_POSSIBILITY_VALUE, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/teambuilding/possibilities/create`,
        {
          possibility,
        },
        null,
        true
      )
        .then((response) => {
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('SAVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: SAVE_POSSIBILITY_VALUE });
          reject({ error: e });
        });
    });
  };
};

export const addActivityValue = (avalue) => {
  return (dispatch, getState) => {
    dispatch({ type: SAVE_ACTIVITY_VALUE, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/teambuilding/activities/values/create`,
        {
          avalue,
        },
        null,
        true
      )
        .then((response) => {
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('SAVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: SAVE_ACTIVITY_VALUE });
          reject({ error: e });
        });
    });
  };
};

export const addActivityCategory = (category) => {
  return (dispatch, getState) => {
    dispatch({ type: SAVE_ACTIVITY_CATEGORY, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/teambuilding/activities/categories/create`,
        {
          category,
        },
        null,
        true
      )
        .then((response) => {
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('SAVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: SAVE_ACTIVITY_CATEGORY });
          reject({ error: e });
        });
    });
  };
};

export const showActivity = (id) => {
  return (dispatch) => {
    dispatch({ type: LOAD_ACTIVITY, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/teambuilding/activities/detail/${id}`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.activity
          ) {
            dispatch({
              type: SET_ACTIVITY,
              activityDetail: response.data.activity,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LOAD_ACTIVITY });
          reject({ error: e });
        });
    });
  };
};

export const saveActivity = () => {
  return (dispatch, getState) => {
    const { teambuilding } = getState();
    const { longDescriptionState, ...rest } = teambuilding.activityDetail;
    dispatch({ type: SAVE_ACTIVITY, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/teambuilding/activities/update`,
        {
          activity: rest,
        },
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.activity
          ) {
            dispatch({
              type: SET_ACTIVITY,
              activityDetail: response.data.activity,
            });
            resolve(response.data);
          } else {
            reject('SAVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: SAVE_ACTIVITY });
          reject({ error: e });
        });
    });
  };
};

export const removeActivity = (id) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_ACTIVITY, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/teambuilding/activities/remove`,
        {
          id,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: REMOVE_ACTIVITY,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: REMOVE_ACTIVITY });
          reject({ error: e });
        });
    });
  };
};

export const updateActivity = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVITY,
      field,
      value,
    });
  };
};

export const resetActivity = (copy) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVITY,
      activityDetail: copy || {},
    });
  };
};

export const listLocations = () => {
  return (dispatch) => {
    dispatch({ type: LIST_LOCATIONS, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/teambuilding/locations`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.locations
          ) {
            dispatch({
              type: SET_LOCATIONS,
              locations: response.data.locations,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_LOCATIONS });
          reject({ error: e });
        });
    });
  };
};

export const showLocation = (id) => {
  return (dispatch) => {
    dispatch({ type: LOAD_LOCATION, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/teambuilding/locations/detail/${id}`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.location
          ) {
            dispatch({
              type: SET_LOCATION,
              locationDetail: response.data.location,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LOAD_LOCATION });
          reject({ error: e });
        });
    });
  };
};

export const saveLocation = () => {
  return (dispatch, getState) => {
    const { teambuilding } = getState();
    const { longDescriptionState, ...rest } = teambuilding.locationDetail;
    dispatch({ type: SAVE_LOCATION, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/teambuilding/locations/update`,
        {
          location: rest,
        },
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.location
          ) {
            dispatch({
              type: SET_LOCATION,
              locationDetail: response.data.location,
            });
            resolve(response.data);
          } else {
            reject('SAVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: SAVE_LOCATION });
          reject({ error: e });
        });
    });
  };
};

export const removeLocation = (id) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_LOCATION, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/teambuilding/locations/remove`,
        {
          id,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: REMOVE_LOCATION,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: REMOVE_LOCATION });
          reject({ error: e });
        });
    });
  };
};

export const updateLocation = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOCATION,
      field,
      value,
    });
  };
};

export const resetLocation = (copy) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOCATION,
      locationDetail: copy || {},
    });
  };
};
