import fetchAxios from '../../middlewares/axios';
import {
  SET_COMPANY,
  UPDATE_COMPANY,
  REMOVE_COMPANY,
  SAVE_COMPANY,
  LOAD_COMPANY,
  LIST_COMPANIES,
  SET_COMPANIES,
  LIST_GAMES,
  SET_GAMES,
  SET_GAME,
  UPDATE_GAME,
  REMOVE_GAME,
  LOAD_GAME,
  SAVE_GAME,
  REMOVE_APP_REGISTRTATION,
  LIST_APP_REGISTRATIONS,
  SET_APP_REGISTRATIONS,
  REMOVE_APP_SCORES,
  LIST_APP_SCORES,
  SET_APP_SCORES,
  LOAD_REGISTRATION,
  SET_REGISTRATION,
  UPLOAD_APP_REGISTRTATION,
} from './types';
import { ROOT_URL_PROTECTED } from '../../../config/axios';
import { searchSafe } from '../../../utils/text';

export const listCompanies = () => {
  return (dispatch) => {
    dispatch({ type: LIST_COMPANIES, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/appjeppa/companies`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.companies
          ) {
            dispatch({
              type: SET_COMPANIES,
              companies: response.data.companies,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_COMPANIES });
          reject({ error: e });
        });
    });
  };
};

export const showCompany = (id) => {
  return (dispatch) => {
    dispatch({ type: LOAD_COMPANY, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/appjeppa/companies/detail/${id}`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.company
          ) {
            dispatch({
              type: SET_COMPANY,
              companyDetail: response.data.company,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LOAD_COMPANY });
          reject({ error: e });
        });
    });
  };
};

export const showRegistration = (id) => {
  return (dispatch) => {
    dispatch({ type: LOAD_REGISTRATION, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/appjeppa/registrations/detail/${id}`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.registration
          ) {
            dispatch({
              type: SET_REGISTRATION,
              registrationDetail: response.data.registration,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LOAD_REGISTRATION });
          reject({ error: e });
        });
    });
  };
};

export const saveCompany = () => {
  return (dispatch, getState) => {
    const { appjeppa } = getState();
    const { longDescriptionState, welcomeDescriptionState, ...rest } =
      appjeppa.companyDetail;

    let newDetail = { ...rest };
    if (newDetail.allPlayFirstGroupGames && newDetail.groups?.length) {
      //const firstGroup = searchSafe(newDetail.groups[0], true);
      for (let g = 1; g < newDetail.groups?.length; g++) {
        const groupName = searchSafe(newDetail.groups[g], true);
        newDetail[`games-${groupName}`] = null;
        /*const gamesOfFirstGroup = JSON.parse(
          JSON.stringify(newDetail[`games-${firstGroup}`])
        );
        newDetail[`games-${groupName}`] = gamesOfFirstGroup;*/
      }
    }
    if (newDetail.groups?.length) {
      for (let g = 0; g < newDetail.groups?.length; g++) {
        const groupName = searchSafe(newDetail.groups[g], true);
        if (newDetail[`games-${groupName}`]) {
          for (let gg = 0; gg < newDetail[`games-${groupName}`].length; gg++) {
            const theG = newDetail[`games-${groupName}`][gg];
            newDetail[`games-${groupName}`][gg] = {
              _id: theG._id,
              url: theG.url,
              title: theG.title,
              titleCMS: theG.titleCMS,
            };
          }
        }
      }
    }

    dispatch({ type: SAVE_COMPANY, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/appjeppa/companies/update`,
        {
          company: newDetail,
        },
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.company
          ) {
            dispatch({
              type: SET_COMPANY,
              companyDetail: response.data.company,
            });
            resolve(response.data);
          } else {
            reject('SAVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: SAVE_COMPANY });
          reject({ error: e });
        });
    });
  };
};

export const removeCompany = (id) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_COMPANY, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/appjeppa/companies/remove`,
        {
          id,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: REMOVE_COMPANY,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: REMOVE_COMPANY });
          reject({ error: e });
        });
    });
  };
};

export const updateCompany = (field, value) => {
  return (dispatch) => {
    return new Promise(function (resolve) {
      dispatch({
        type: UPDATE_COMPANY,
        field,
        value,
      });
      resolve();
    });
  };
};

export const resetCompany = (copy) => {
  return (dispatch) => {
    dispatch({
      type: SET_COMPANY,
      companyDetail: copy || {},
    });
  };
};

export const listGames = () => {
  return (dispatch) => {
    dispatch({ type: LIST_GAMES, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/appjeppa/games`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.games
          ) {
            dispatch({
              type: SET_GAMES,
              games: response.data.games,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_GAMES });
          reject({ error: e });
        });
    });
  };
};

export const showGame = (id) => {
  return (dispatch) => {
    dispatch({ type: LOAD_GAME, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/appjeppa/games/detail/${id}`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.game
          ) {
            dispatch({
              type: SET_GAME,
              gameDetail: response.data.game,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LOAD_GAME });
          reject({ error: e });
        });
    });
  };
};

export const saveGame = () => {
  return (dispatch, getState) => {
    const { appjeppa } = getState();
    const { longDescriptionState, ...rest } = appjeppa.gameDetail;
    dispatch({ type: SAVE_GAME, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/appjeppa/games/update`,
        {
          game: rest,
        },
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.game
          ) {
            dispatch({
              type: SET_GAME,
              gameDetail: response.data.game,
            });
            resolve(response.data);
          } else {
            reject('SAVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: SAVE_GAME });
          reject({ error: e });
        });
    });
  };
};

export const resetCompanyScores = (id) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_GAME, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/appjeppa/companies/resetscores`,
        {
          id,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: REMOVE_GAME,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: REMOVE_GAME });
          reject({ error: e });
        });
    });
  };
};

export const resetCompanyRegistrations = (id) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_GAME, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/appjeppa/companies/resetregistrations`,
        {
          id,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: REMOVE_GAME,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: REMOVE_GAME });
          reject({ error: e });
        });
    });
  };
};

export const removeGame = (id) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_GAME, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/appjeppa/games/remove`,
        {
          id,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: REMOVE_GAME,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: REMOVE_GAME });
          reject({ error: e });
        });
    });
  };
};

export const updateGame = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_GAME,
      field,
      value,
    });
  };
};

export const resetGame = (copy) => {
  return (dispatch) => {
    dispatch({
      type: SET_GAME,
      gameDetail: copy || {},
    });
  };
};

export const listAppRegistrations = () => {
  return (dispatch) => {
    dispatch({ type: LIST_APP_REGISTRATIONS, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/appjeppa/registrations`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.registrations
          ) {
            dispatch({
              type: SET_APP_REGISTRATIONS,
              appRegistrations: response.data.registrations,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_APP_REGISTRATIONS });
          reject({ error: e });
        });
    });
  };
};

export const uploadTeams = (teams) => {
  return (dispatch) => {
    dispatch({ type: UPLOAD_APP_REGISTRTATION, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/appjeppa/registrations/upload`,
        {
          teams,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: UPLOAD_APP_REGISTRTATION,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: UPLOAD_APP_REGISTRTATION });
          reject({ error: e });
        });
    });
  };
};

export const removeAppRegistration = (id) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_APP_REGISTRTATION, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/appjeppa/registrations/remove`,
        {
          id,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: REMOVE_APP_REGISTRTATION,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: REMOVE_APP_REGISTRTATION });
          reject({ error: e });
        });
    });
  };
};

export const listAppScores = () => {
  return (dispatch) => {
    dispatch({ type: LIST_APP_SCORES, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/appjeppa/scores`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.scores
          ) {
            dispatch({
              type: SET_APP_SCORES,
              appScores: response.data.scores,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_APP_SCORES });
          reject({ error: e });
        });
    });
  };
};

export const removeAppScore = (id) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_APP_SCORES, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/appjeppa/scores/remove`,
        {
          id,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: REMOVE_APP_SCORES,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: REMOVE_APP_SCORES });
          reject({ error: e });
        });
    });
  };
};
