import { LIST_CUSTOMERS } from "../actions/customers/types";

const initialState = {
  isFetching: false,
  customers: [],
};

const customers = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CUSTOMERS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        customers: action.customers || state.customers,
      };
    default:
      return state;
  }
};

export default customers;
