export function setToken(jwt) {
  localStorage.setItem('jwtToken', jwt);
}

export function getToken() {
  return localStorage.getItem('jwtToken') || null;
}

export function clearToken() {
  localStorage.removeItem('jwtToken');
}

export function clear() {
  clearToken();
}
