import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {
  LIST_TEAMBUILDING,
  UPDATE_TEAMBUILDING,
  SET_TEAMBUILDING,
  SET_TEAMBUILDINGS,
  SAVE_TEAMBUILDING,
  REMOVE_TEAMBUILDING,
  SAVE_LOCATION,
  SAVE_ACTIVITY,
  REMOVE_ACTIVITY,
  REMOVE_LOCATION,
  LIST_ACTIVITIES,
  LIST_LOCATIONS,
  UPDATE_ACTIVITY,
  UPDATE_LOCATION,
  SET_ACTIVITY,
  SET_LOCATION,
  SET_ACTIVITIES,
  SET_LOCATIONS,
  LIST_ACTIVITY_CATEGORIES,
  SET_ACTIVITY_CATEGORIES,
  LIST_ACTIVITY_VALUES,
  SET_ACTIVITY_VALUES,
  LIST_POSSIBILITIES,
  SET_POSSIBILITIES,
  SAVE_POSSIBILITY_VALUE,
  SAVE_ACTIVITY_VALUE,
  SAVE_ACTIVITY_CATEGORY,
} from '../actions/teambuilding/types';

const initialState = {
  isFetching: false,
  teambuilding: [],
  teambuildingDetail: {},
  activities: [],
  activityDetail: {},
  locations: [],
  locationDetail: {},
  activityValues: [],
  activityCategories: [],
  possibilities: [],
};

const teambuilding = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ACTIVITY_CATEGORIES:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case SET_ACTIVITY_CATEGORIES:
      return {
        ...state,
        isFetching: false,
        activityCategories: action.activityCategories,
      };
    case LIST_ACTIVITY_VALUES:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case LIST_POSSIBILITIES:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case SET_POSSIBILITIES:
      return {
        ...state,
        isFetching: false,
        possibilities: action.possibilities,
      };
    case SET_ACTIVITY_VALUES:
      return {
        ...state,
        isFetching: false,
        activityValues: action.activityValues,
      };
    case LIST_TEAMBUILDING:
      return {
        ...state,
        isFetching: action.isFetching || false,
        teambuilding: action.teambuilding || state.teambuilding,
      };
    case LIST_ACTIVITIES:
      return {
        ...state,
        isFetching: action.isFetching || false,
        activities: action.activities || state.activities,
      };
    case LIST_LOCATIONS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        locations: action.locations || state.locations,
      };
    case REMOVE_TEAMBUILDING:
    case REMOVE_ACTIVITY:
    case REMOVE_LOCATION:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_TEAMBUILDING:
      return {
        ...state,
        teambuildingDetail: {
          ...state.teambuildingDetail,
          [action.field]: action.value,
          longDescription:
            action.field === 'longDescriptionState'
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.teambuildingDetail.longDescription,
        },
      };
    case UPDATE_ACTIVITY:
      return {
        ...state,
        activityDetail: {
          ...state.activityDetail,
          [action.field]: action.value,
          longDescription:
            action.field === 'longDescriptionState'
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.activityDetail.longDescription,
        },
      };
    case UPDATE_LOCATION:
      return {
        ...state,
        locationDetail: {
          ...state.locationDetail,
          [action.field]: action.value,
          longDescription:
            action.field === 'longDescriptionState'
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.locationDetail.longDescription,
        },
      };
    case SET_TEAMBUILDING:
      return {
        ...state,
        isFetching: false,
        teambuildingDetail: action.teambuildingDetail,
      };
    case SET_ACTIVITY:
      return {
        ...state,
        isFetching: false,
        activityDetail: action.activityDetail,
      };
    case SET_LOCATION:
      return {
        ...state,
        isFetching: false,
        locationDetail: action.locationDetail,
      };
    case SET_TEAMBUILDINGS:
      return {
        ...state,
        isFetching: false,
        teambuilding: action.teambuilding,
      };
    case SET_ACTIVITIES:
      return {
        ...state,
        isFetching: false,
        activities: action.activities,
      };
    case SET_LOCATIONS:
      return {
        ...state,
        isFetching: false,
        locations: action.locations,
      };
    case SAVE_TEAMBUILDING:
    case SAVE_LOCATION:
    case SAVE_ACTIVITY:
    case SAVE_POSSIBILITY_VALUE:
    case SAVE_ACTIVITY_VALUE:
    case SAVE_ACTIVITY_CATEGORY:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default teambuilding;
