import {
  LIST_BRANDS,
  UPDATE_BRAND,
  SET_BRAND,
  SET_BRANDS,
  SAVE_BRAND,
  REMOVE_BRAND,
} from "../actions/brands/types";

const initialState = {
  isFetching: false,
  brands: [],
  brandDetail: {},
};

const brands = (state = initialState, action) => {
  switch (action.type) {
    case LIST_BRANDS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        brands: action.brands || state.brands,
      };
    case REMOVE_BRAND:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_BRAND:
      return {
        ...state,
        brandDetail: {
          ...state.brandDetail,
          [action.field]: action.value,
        },
      };
    case SET_BRAND:
      return {
        ...state,
        isFetching: false,
        brandDetail: action.brandDetail,
      };
    case SET_BRANDS:
      return {
        ...state,
        isFetching: false,
        brands: action.brands,
      };
    case SAVE_BRAND:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default brands;
