import React, { Component } from 'react';
import FormBlock from '../../../components/FormBlock';
import './style.scss';
import CustomForm from '../../../components/CustomForm';
import { showSnack } from '../../../redux/actions/app';
import { connect } from 'react-redux';
import FileUpload from '../../../components/FileUpload';
import { awsAccessKey, awsSecretKey, bucketName } from '../../../config';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import S3FileUpload from 'react-s3';
import swal from 'sweetalert';
import { faCopy, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  resetGame,
  saveGame,
  showGame,
  updateGame,
} from '../../../redux/actions/appjeppa';
import { searchSafe } from '../../../utils/text';

class AppGameDetail extends Component {
  state = {
    id:
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : null,
    newScoreMatrix: {},
    newQuizQuestion: {},
  };

  componentDidMount = () => {
    if (this.state.id) {
      this.props.doShowGame(this.state.id).catch(() => {
        this.props.doSnack('Probleem bij openen bedrijf');
        this.props.history.push('/appjeppagames');
      });
    } else {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.gameCopy
      ) {
        let newCopy = { ...this.props.location.state.gameCopy };
        newCopy.copyFromGame = JSON.parse(JSON.stringify(newCopy._id));
        delete newCopy.language;
        delete newCopy._id;
        this.props.doResetGame(newCopy);
      } else {
        this.props.doResetGame();
      }
    }
  };

  onHeaderUpload = (files, mobile) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw spel niet op te slaan.',
      'success'
    );
    this.props.doUpdateGame('img', files[0].location);
  };

  onVideoUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw spel niet op te slaan.',
      'success'
    );
    this.props.doUpdateGame('video', files[0].location);
  };

  onInstructionUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw spel niet op te slaan.',
      'success'
    );
    this.props.doUpdateGame('instructionImg', files[0].location);
  };

  onPDFUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw spel niet op te slaan.',
      'success'
    );
    this.props.doUpdateGame('registrationPDF', files[0].location);
  };

  onCarrouselImgsComplete = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw spel niet op te slaan.',
      'success'
    );
    const imgs = files.map((file) => file.location);
    this.props.doUpdateGame('carrouselImgs', imgs);
  };

  onEditorStateChange = (editorState) => {
    this.props.doUpdateGame('longDescriptionState', editorState);
  };

  saveGame = () => {
    const {
      _id,
      titleCMS,
      title,
      info,
      url,
      scoreType,
      disableScore,
      minSeconds,
      maxSeconds,
      minimum,
      maximum,
      isQuiz,
      quizQuestions,
    } = this.props.gameDetail;
    if (
      titleCMS?.length < 2 ||
      title?.length < 2 ||
      info?.length < 2 ||
      url?.length < 3
    ) {
      return this.props.doSnack(
        'Niet alle velden werden correct ingevuld. Controleer de verplichte velden met *.',
        'error'
      );
    }
    if (!disableScore && !isQuiz) {
      if (!scoreType) {
        return this.props.doSnack('Type score moet gekozen worden *.', 'error');
      }
      if (
        scoreType === 'time' &&
        (!minSeconds?.length || !maxSeconds?.length)
      ) {
        return this.props.doSnack(
          'Minimum en maximum seconden moeten ingevuld worden *.',
          'error'
        );
      }
      if (scoreType === 'number' && (!minimum?.length || !maximum?.length)) {
        return this.props.doSnack(
          'Minimum en maximum seconden moeten ingevuld worden *.',
          'error'
        );
      }
    }
    if (isQuiz && !quizQuestions?.length) {
      return this.props.doSnack(
        'Minimum 1 vraag voor de quiz invullen.',
        'error'
      );
    }

    this.props
      .doSaveGame()
      .then((result) => {
        if (!_id) {
          this.props.doSnack('Spel toegevoegd', 'success');
          return this.props.history.push(
            `/appjeppagames/detail/${result.game._id}`
          );
        } else {
          return this.props.doSnack('Spel bijgewerkt', 'success');
        }
      })
      .catch((e) => {
        if (e && e.error && typeof e.error === 'string') {
          this.props.doSnack(e.error, 'error');
        } else {
          this.props.doSnack('Probleem bij opslaan', 'error');
        }
      });
  };

  copyToNewGame = (e) => {
    e.preventDefault();
    const { title, url, ...rest } = this.props.gameDetail;
    this.props.history.push('/appjeppagames/new', {
      gameCopy: { ...rest },
    });
  };

  uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const config = {
        bucketName: bucketName,
        region: 'eu-central-1',
        accessKeyId: awsAccessKey,
        secretAccessKey: awsSecretKey,
        dirName: 'camps' + '_' + Date.now(),
      };
      S3FileUpload.uploadFile(file, config)
        .then((data) => {
          return resolve({
            data: {
              link: data.location,
            },
          });
        })
        .catch((err) => {
          console.log(err);
          return reject();
        });
    });
  };

  updateNewCategory = (val) => {
    this.setState({
      ...this.state,
      newCategory: {
        category: val,
      },
    });
  };

  addNewCategory = (e) => {
    e.preventDefault();
    const { campCategories } = this.props;
    const cat = this.state.newCategory.category;
    for (let c = 0; c < campCategories.length; c++) {
      if (campCategories[c].category === cat) {
        return swal('Probleem', 'Deze category bestaat al', 'error');
      }
    }
    this.props
      .doAddCampCategory(cat)
      .then(() => {
        this.setState(
          {
            newCategory: {},
          },
          () => {
            this.props.doSnack('Categorie toegevoegd', 'success');
            this.props.doListCampCategories();
          }
        );
      })
      .catch((e) => {
        if (e && e.error && typeof e.error === 'string') {
          this.props.doSnack(e.error, 'error');
        } else {
          this.props.doSnack('Probleem bij opslaan', 'error');
        }
      });
  };

  addScoreMatrix = () => {
    const { scoreMatrix } = this.props.gameDetail;
    const { newScoreMatrix } = this.state;
    const score = parseInt(newScoreMatrix?.score);
    const points = parseInt(newScoreMatrix?.points);
    if (!score) {
      return swal(
        'Probleem',
        'Geen correcte score. Deze moet hoger zijn als 0.',
        'error'
      );
    }
    if (scoreMatrix && scoreMatrix.hasOwnProperty(score)) {
      return swal(
        'Probleem',
        'Deze score bestaat al onder deze matrix.',
        'error'
      );
    }
    let newMatrix = scoreMatrix ? scoreMatrix : {};
    newMatrix[score] = points;
    let currentObs = Object.keys(newMatrix);
    currentObs = currentObs.sort((a, b) => {
      if (parseInt(a) < parseInt(b)) {
        return -1;
      } else if (parseInt(a) > parseInt(b)) {
        return 1;
      } else {
        return 0;
      }
    });
    let sortedMatrix = {};
    for (var co = 0; co < currentObs.length; co++) {
      sortedMatrix[currentObs[co]] = newMatrix[currentObs[co]];
    }
    this.props.doUpdateGame('scoreMatrix', sortedMatrix);
    this.setState({
      newScoreMatrix: {},
    });
    this.props.doSnack(
      'Score toegevoegd. Vergeet niet op te slaan.',
      'success'
    );
  };

  removeScoreMatrix = (e, score) => {
    e.preventDefault();
    const { scoreMatrix } = this.props.gameDetail;
    let newMatrix = { ...scoreMatrix };
    delete newMatrix[score];
    this.props.doUpdateGame('scoreMatrix', newMatrix);
    this.setState({
      newScoreMatrix: {},
    });
    this.props.doSnack(
      'Score verwijderd. Vergeet niet op te slaan.',
      'success'
    );
  };

  removeGroup = (groupIndex) => {
    let { groups } = this.props.gameDetail;
    groups.splice(groupIndex, 1);
    this.props.doUpdateGame('groups', groups);
    this.props.doSnack(
      'Groep verwijderd. Vergeet item niet op te slaan.',
      'success'
    );
  };

  addSelectedGame = (game) => {
    const { games } = this.props.gameDetail;
    let newGames = games?.length ? games : [];
    newGames.push(game);
    this.props.doUpdateGame('games', newGames);
    this.props.doSnack('Spel toegevoegd. Vergeet niet op te slaan.', 'success');
  };

  isGameSelected = (game) => {
    let { games } = this.props.gameDetail;
    if (!games?.length) {
      return false;
    }
    for (let g = 0; g < games.length; g++) {
      if (games[g]._id === game._id) {
        return true;
      }
    }
    return false;
  };

  updateNewQuizQuestion = (prop, val) => {
    this.setState({
      ...this.state,
      newQuizQuestion: {
        ...this.state.newQuizQuestion,
        [prop]: val,
      },
    });
  };

  addNewQuizQuestionAnswer = () => {
    if (this.state.newQuizQuestion?.answer?.length) {
      const answers = this.state.newQuizQuestion.answers || [];
      this.setState({
        ...this.state,
        newQuizQuestion: {
          ...this.state.newQuizQuestion,
          answer: undefined,
          answers: answers.concat([this.state.newQuizQuestion?.answer]),
        },
      });
    }
  };

  addQuestion = (e) => {
    e.preventDefault();
    let question = { ...this.state.newQuizQuestion };
    delete question.answer;
    if (question.correctAnswerIndex >= question.answers?.length) {
      return this.props.doSnack('Correct antwoord niet geldig', 'error');
    }
    const questions = this.props.gameDetail?.quizQuestions || [];
    this.props.doUpdateGame('quizQuestions', questions.concat([question]));
    this.setState({
      ...this.state,
      newQuizQuestion: {},
    });
    this.props.doSnack(
      'Vraag toegevoegd. Vergeet item niet op te slaan.',
      'success'
    );
  };

  removeGameQuestion = (e, groupIndex) => {
    e.preventDefault();
    let { quizQuestions } = this.props.gameDetail;
    quizQuestions.splice(groupIndex, 1);
    this.props.doUpdateGame('quizQuestions', quizQuestions);
    this.props.doSnack(
      'Vraag verwijderd. Vergeet item niet op te slaan.',
      'success'
    );
  };

  removeGameQuestionAnswer = (e, groupIndex) => {
    e.preventDefault();
    let { newQuizQuestion } = this.state;
    newQuizQuestion.answers.splice(groupIndex, 1);
    delete newQuizQuestion.correctAnswerIndex;
    this.setState({
      ...this.state,
      newQuizQuestion,
    });
  };

  removeGame = (gameIndex) => {
    let { games } = this.props.gameDetail;
    games.splice(gameIndex, 1);
    this.props.doUpdateGame('games', games);
    this.props.doSnack(
      'Spel verwijderd. Vergeet item niet op te slaan.',
      'success'
    );
  };

  removeFromWaitingList = (index) => {
    const { gameDetail } = this.props;
    let waitingList = [...gameDetail.waitingList];
    waitingList.splice(index, 1);
    this.props.doUpdateGame('waitingList', waitingList);
    this.props.doSnack(
      'E-mailadres verwijderd van wachtlijst. Vergeet spel niet op te slaan.',
      'success'
    );
  };

  sendWaitingListMail = () => {
    const { gameDetail } = this.props;
    swal({
      title: 'Mail verzenden',
      text: 'Bent u zeker dat u de wachtlijst mail wil verzenden?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doMailWaitinglist(gameDetail._id)
          .then(() => {
            this.props.doSnack('Mail verzonden', 'success');
          })
          .catch(() => {
            this.props.doSnack('Probleem bij verzenden');
          });
      }
    });
  };

  setURL = () => {
    const { gameDetail } = this.props;
    if (!gameDetail.url || !gameDetail.url.length) {
      this.props.doUpdateGame(
        'url',
        searchSafe(gameDetail.title, false, true).toLowerCase()
      );
    }
  };

  render() {
    const { gameDetail } = this.props;
    const { newScoreMatrix, newQuizQuestion } = this.state;

    const longDescriptionState =
      gameDetail && gameDetail.longDescriptionState
        ? gameDetail.longDescriptionState
        : gameDetail.longDescription
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(gameDetail.longDescription)
            )
          )
        : EditorState.createEmpty();

    return (
      <div>
        <div className="gameDetail-root">
          <div className="gameDetail-root__header">
            <button className="btn btn-primary" onClick={() => this.saveGame()}>
              Sla gegevens op
            </button>
          </div>
          <div className="gameDetail-root__header"></div>
          <div className="gameDetail-root__form">
            <FormBlock
              title={`Details ${
                gameDetail.copyFromLanguage
                  ? `(Kopie van ${gameDetail.copyFromLanguage})`
                  : ''
              }`}
            >
              <form>
                <CustomForm>
                  {gameDetail._id && (
                    <button
                      className="btn btn-warning"
                      onClick={(e) => this.copyToNewGame(e)}
                    >
                      <FontAwesomeIcon icon={faCopy} /> Maak variant/copy van
                      spel
                    </button>
                  )}
                  <label>
                    Titel van het spel voor CMS * (vb. Staande wip) (indien je
                    variaties hebt voor leeftijden of scholen geef deze dan in
                    als Staande Wip - Jeugd | Staande Wip - Lagere School)
                  </label>
                  <input
                    id="titleCMS"
                    name="titleCMS"
                    value={gameDetail.titleCMS || ''}
                    onChange={(e) =>
                      this.props.doUpdateGame('titleCMS', e.target.value)
                    }
                  />
                  <label>Titel van het spel in de app (hou dit kort)</label>
                  <input
                    id="title"
                    name="title"
                    value={gameDetail.title || ''}
                    onChange={(e) =>
                      this.props.doUpdateGame('title', e.target.value)
                    }
                    onBlur={() => this.setURL()}
                  />
                  <label>URL * (geen spaties of speciale tekens)</label>
                  <input
                    id="url"
                    name="url"
                    value={gameDetail.url || ''}
                    onChange={(e) =>
                      this.props.doUpdateGame('url', e.target.value)
                    }
                    disabled={gameDetail._id}
                  />
                  <label htmlFor="info">Spelinfo kort (1-2 zinnen) *</label>
                  <textarea
                    id="info"
                    name="info"
                    value={gameDetail.info || ''}
                    onChange={(e) =>
                      this.props.doUpdateGame('info', e.target.value)
                    }
                    rows={6}
                  />
                  <label htmlFor="name">
                    Lange spelinstructies (zoals kijkwijzer)
                  </label>
                  <Editor
                    editorState={longDescriptionState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="wysiwygeditor"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                      image: {
                        uploadCallback: this.uploadImageCallBack,
                        alt: { present: true, mandatory: false },
                        previewImage: true,
                        inputAccept: 'image/jpeg,image/jpg,image/png',
                      },
                    }}
                  />
                  <FileUpload
                    onUploadComplete={(files) =>
                      this.onInstructionUpload(files)
                    }
                    onError={() =>
                      this.props.doSnack('Probleem met upload', 'error')
                    }
                    bucketName={bucketName}
                    awsAccessKey={awsAccessKey}
                    awsSecretKey={awsSecretKey}
                    multiple={false}
                    accept="image/png, image/jpeg"
                    prefix="appgames"
                  />
                  <label>
                    Instructietekening (2000 x 600 is ideaal formaat)
                  </label>
                  {gameDetail.instructionImg && (
                    <div>
                      <img
                        src={gameDetail.instructionImg}
                        width="auto"
                        style={{ maxHeight: 200, marginBottom: 6 }}
                        alt="head"
                      />
                    </div>
                  )}
                  <label htmlFor="isQuizChk">
                    <input
                      id="isQuizChk"
                      name="isQuizChk"
                      type="checkbox"
                      checked={gameDetail.isQuiz ? 'checked' : ''}
                      onChange={(e) =>
                        this.props.doUpdateGame('isQuiz', !gameDetail.isQuiz)
                      }
                      style={{ marginRight: 10 }}
                    />
                    Dit spel is van het type QUIZ
                  </label>
                  {!gameDetail.isQuiz ? (
                    <label htmlFor="disableScore">
                      <input
                        id="disableScore"
                        name="disableScore"
                        type="checkbox"
                        checked={gameDetail.disableScore ? 'checked' : ''}
                        onChange={(e) =>
                          this.props.doUpdateGame(
                            'disableScore',
                            !gameDetail.disableScore
                          )
                        }
                        style={{ marginRight: 10 }}
                      />
                      Voor dit spel geen score invullen
                    </label>
                  ) : null}
                  <div style={{ width: '100%', height: 20 }} />
                  {!gameDetail.disableScore && !gameDetail.isQuiz ? (
                    <>
                      <select
                        name="scoreType"
                        value={gameDetail.scoreType || ''}
                        onChange={(e) =>
                          this.props.doUpdateGame(
                            'scoreType',
                            e.currentTarget.value
                          )
                        }
                        style={{ marginTop: 20 }}
                      >
                        <option value="">Kies type score *</option>
                        <option value="number">Score op punten</option>
                        <option value="time">Score op tijd</option>
                      </select>
                      {gameDetail.scoreType === 'time' ? (
                        <>
                          <label>
                            Minimum aantal seconden voor ingave tijd *
                          </label>
                          <input
                            id="minSeconds"
                            name="minSeconds"
                            value={gameDetail.minSeconds || ''}
                            onChange={(e) =>
                              this.props.doUpdateGame(
                                'minSeconds',
                                e.target.value
                              )
                            }
                          />
                          <label>
                            Maximum aantal seconden voor ingave tijd *
                          </label>
                          <input
                            id="maxSeconds"
                            name="maxSeconds"
                            value={gameDetail.maxSeconds || ''}
                            onChange={(e) =>
                              this.props.doUpdateGame(
                                'maxSeconds',
                                e.target.value
                              )
                            }
                          />
                        </>
                      ) : null}
                      {gameDetail.scoreType === 'number' ? (
                        <>
                          <label>Minimum score voor ingave punten *</label>
                          <input
                            id="minimum"
                            name="minimum"
                            value={gameDetail.minimum || ''}
                            onChange={(e) =>
                              this.props.doUpdateGame('minimum', e.target.value)
                            }
                          />
                          <label>Maximum score voor ingave punten *</label>
                          <input
                            id="maximum"
                            name="maximum"
                            value={gameDetail.maximum || ''}
                            onChange={(e) =>
                              this.props.doUpdateGame('maximum', e.target.value)
                            }
                          />
                        </>
                      ) : null}

                      <label htmlFor="scoreError">
                        Foutmelding bij een foutieve ingave van de score (wees
                        kort en duidelijk) *
                      </label>
                      <textarea
                        id="scoreError"
                        name="scoreError"
                        placeholder="Vb. De tijd die je hebt toegevoegd is niet geldig, deze is hoger dan de toegestane tijd of lager dan de minimum tijd van 30 seconden."
                        value={gameDetail.scoreError || ''}
                        onChange={(e) =>
                          this.props.doUpdateGame('scoreError', e.target.value)
                        }
                        rows={4}
                      />
                      <label htmlFor="scoreHint">
                        Hint bij ingave van de score (wees kort en duidelijk) *
                      </label>
                      <textarea
                        id="scoreHint"
                        name="scoreHint"
                        placeholder="Vb. Geef de totale tijd in minuten en seconden in. Als je totale tijd 1 minuut en 45 seconden was, vul dan 1:45 in onderstaand veld. Is je tijd maar 45 seconden vul dan 0:45 in."
                        value={gameDetail.scoreHint || ''}
                        onChange={(e) =>
                          this.props.doUpdateGame('scoreHint', e.target.value)
                        }
                        rows={4}
                      />
                      <label htmlFor="useScoreMatrix">
                        <input
                          id="useScoreMatrix"
                          name="useScoreMatrix"
                          type="checkbox"
                          checked={gameDetail.useScoreMatrix ? 'checked' : ''}
                          onChange={(e) =>
                            this.props.doUpdateGame(
                              'useScoreMatrix',
                              !gameDetail.useScoreMatrix
                            )
                          }
                          style={{ marginRight: 10 }}
                        />
                        Gebruik een scorematrix waarbij je zelf punten toekent
                        aan de waarden tussen minimum en maximum (vb. bij IPS
                        werd dit gebruikt)
                      </label>
                      {gameDetail.useScoreMatrix ? (
                        <>
                          <label>
                            <strong>Scorematrix</strong>
                          </label>
                          <table
                            border="1"
                            width="100%"
                            className="gameDetail-root__form__table"
                          >
                            <tbody>
                              {(gameDetail.scoreMatrix &&
                                Object.keys(gameDetail.scoreMatrix).length &&
                                Object.keys(gameDetail.scoreMatrix).map(
                                  (scoreM, s) => (
                                    <tr key={s}>
                                      <td>{scoreM}</td>
                                      <td>{gameDetail.scoreMatrix[scoreM]}</td>
                                      <td style={{ textAlign: 'right' }}>
                                        <button
                                          onClick={(e) =>
                                            this.removeScoreMatrix(e, scoreM)
                                          }
                                        >
                                          <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                )) ||
                                null}
                            </tbody>
                          </table>
                          <label>
                            Nieuwe score en punten voor matrix toevoegen
                          </label>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <span>Score</span>
                            <input
                              id="newmatrix"
                              name="newmatrix"
                              value={newScoreMatrix.score || ''}
                              onChange={(e) =>
                                this.setState({
                                  ...this.state,
                                  newScoreMatrix: {
                                    ...this.state.newScoreMatrix,
                                    score: e.target.value,
                                  },
                                })
                              }
                              style={{ width: 120, marginRight: 20 }}
                            />
                            <span>Punten (max 15)</span>
                            <input
                              id="newmatrix"
                              name="newmatrix"
                              value={newScoreMatrix.points || ''}
                              onChange={(e) =>
                                this.setState({
                                  ...this.state,
                                  newScoreMatrix: {
                                    ...this.state.newScoreMatrix,
                                    points: e.target.value,
                                  },
                                })
                              }
                              style={{ width: 120 }}
                            />
                          </div>
                          {newScoreMatrix?.score && newScoreMatrix?.points && (
                            <button
                              className="btn btn-secondary"
                              onClick={() => this.addScoreMatrix()}
                            >
                              Voeg score matrix toe
                            </button>
                          )}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </CustomForm>
              </form>
            </FormBlock>
            {gameDetail.isQuiz ? (
              <FormBlock title="Quizvragen speltype QUIZ">
                <form>
                  <CustomForm>
                    {gameDetail.quizQuestions?.length ? (
                      <div style={{ marginTop: 16 }}>
                        <table
                          border="1"
                          width="100%"
                          className="gamedetail-root__form__table"
                        >
                          <tbody>
                            {gameDetail.quizQuestions.map((gQuestion, g) => (
                              <tr key={g}>
                                <td>
                                  {g + 1}.{gQuestion.question}
                                </td>
                                <td>
                                  <ul style={{ listStyleType: 'none' }}>
                                    {gQuestion.answers?.map((answ, a) => (
                                      <li key={a}>
                                        {a + 1}:{answ}
                                      </li>
                                    ))}
                                  </ul>
                                </td>
                                <td>
                                  Correct:{' '}
                                  {
                                    gQuestion.answers[
                                      gQuestion.correctAnswerIndex
                                    ]
                                  }
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  <button
                                    onClick={(e) =>
                                      this.removeGameQuestion(e, g)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </td>
                              </tr>
                            )) || null}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p>Nog geen vragen toegevoegd</p>
                    )}
                    <div style={{ marginTop: 10 }} />
                    <label>Nieuwe vraag toevoegen (multiple-choice)</label>
                    <input
                      id="quizquestion"
                      name="quizquestion"
                      value={newQuizQuestion.question || ''}
                      onChange={(e) =>
                        this.updateNewQuizQuestion('question', e.target.value)
                      }
                    />
                    <table
                      border="1"
                      width="100%"
                      className="gamedetail-root__form__table"
                    >
                      <tbody>
                        {newQuizQuestion.answers?.map((qansw, q) => (
                          <tr key={q}>
                            <td>{qansw}</td>
                            <td style={{ textAlign: 'right' }}>
                              <button
                                onClick={(e) =>
                                  this.removeGameQuestionAnswer(e, q)
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        )) || null}
                      </tbody>
                    </table>
                    <label>Nieuwe antwoord toevoegen</label>
                    <input
                      id="quizanzwer"
                      name="quizanzwer"
                      value={newQuizQuestion.answer || ''}
                      onChange={(e) =>
                        this.updateNewQuizQuestion('answer', e.target.value)
                      }
                    />
                    <button
                      onClick={() => this.addNewQuizQuestionAnswer()}
                      className="btn btn-info"
                      style={{ marginBottom: 6 }}
                      disabled={!newQuizQuestion.answer?.length}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Voeg antwoord toe
                    </button>
                    <select
                      name="correctanswer"
                      value={newQuizQuestion.correctAnswerIndex || ''}
                      onChange={(e) =>
                        this.updateNewQuizQuestion(
                          'correctAnswerIndex',
                          e.currentTarget.value
                        )
                      }
                      style={{ marginTop: 16 }}
                    >
                      <option value="">Kies een correct antwoord</option>
                      {newQuizQuestion.answers?.map((answ, a) => (
                        <option key={a} value={a}>
                          {answ}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={(e) => this.addQuestion(e)}
                      className="btn btn-warning"
                      style={{ marginBottom: 6 }}
                      disabled={
                        !newQuizQuestion.question?.length ||
                        !newQuizQuestion.answers?.length ||
                        newQuizQuestion.correctAnswerIndex === undefined
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} /> Voeg vraag toe
                    </button>
                  </CustomForm>
                </form>
              </FormBlock>
            ) : null}
            <FormBlock title="Look & feel">
              <form>
                <CustomForm>
                  <FileUpload
                    onUploadComplete={(files) => this.onHeaderUpload(files)}
                    onError={() =>
                      this.props.doSnack('Probleem met upload', 'error')
                    }
                    bucketName={bucketName}
                    awsAccessKey={awsAccessKey}
                    awsSecretKey={awsSecretKey}
                    multiple={false}
                    accept="image/png, image/jpeg"
                    prefix="appgames"
                  />
                  <label>Hoofdafbeelding (800 x 352 is ideaal formaat)</label>
                  {gameDetail.img && (
                    <div>
                      <img
                        src={gameDetail.img}
                        width="auto"
                        style={{ maxHeight: 320, marginBottom: 6 }}
                        alt="head"
                      />
                    </div>
                  )}
                  <label>
                    Spel video (in plaats van een afbeelding) enkel in mp4 en
                    max 2MB
                  </label>
                  {gameDetail.video && (
                    <video width="100%" height="300" controls>
                      <source src={gameDetail.video} type="video/mp4" />
                    </video>
                  )}
                  <FileUpload
                    onUploadComplete={this.onVideoUpload}
                    onError={() =>
                      this.props.doSnack('Probleem met upload', 'error')
                    }
                    bucketName={bucketName}
                    awsAccessKey={awsAccessKey}
                    awsSecretKey={awsSecretKey}
                    multiple={false}
                    accept=".mp4"
                    prefix="appgames"
                    maxSizeKB={2000}
                  />
                  <button
                    className="btn btn-danger"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.doUpdateGame('video', null);
                      swal(
                        'Verwijderd',
                        'Vergeet het spel niet op te slaan',
                        'success'
                      );
                    }}
                  >
                    Verwijder video
                  </button>
                </CustomForm>
              </form>
            </FormBlock>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gameDetail: state.appjeppa.gameDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doSaveGame: () => {
      return dispatch(saveGame());
    },
    doResetGame: (copy) => {
      dispatch(resetGame(copy));
    },
    doShowGame: (id) => {
      return dispatch(showGame(id));
    },
    doUpdateGame: (field, val) => {
      dispatch(updateGame(field, val));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppGameDetail);
