import React, { Component } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import swal from "sweetalert";
import { csvTojs } from "../../utils/csv";
import "./style.scss";

class CSVReader extends Component {
  state = {
    files: undefined,
    uploading: false,
    csvLines: undefined,
  };

  fileChange = (e) => {
    e.preventDefault();
    let files = e.target.files;

    this.setState({
      files,
    });
  };

  uploadFiles = (e) => {
    e.preventDefault();
    const { files } = this.state;
    if (!files) return;
    const currentFile = files[0];
    const readCSVFile = () => {
      let reader = new FileReader();
      reader.readAsText(currentFile);
      reader.onload = (event) => {
        let csvData = event.target.result;
        let data = csvTojs(csvData, ",");
        if (data && data.length > 0) {
          this.setState({
            uploading: false,
            files: undefined,
            csvLines: data,
          });
          if (this.props.onCSVRead) {
            console.log("csv reading finished");
            this.props.onCSVRead(data);
          }
        } else {
          swal("Probleem", "Het bestand kon niet ingeladen worden.", "error");
        }
      };
      reader.onerror = () => {
        console.log("Unable to read " + currentFile.fileName);
        swal("Probleem", "Het bestand kon niet ingeladen worden.", "error");
      };
    };
    this.setState(
      {
        uploading: true,
      },
      () => readCSVFile(),
    );
  };

  render() {
    const { uploading, files, csvLines } = this.state;
    return (
      <div className="csvuploader-root">
        <input
          id="csvuploader"
          type="file"
          onChange={(e) => this.fileChange(e)}
          accept=".csv"
          multiple={false}
          className="imageupload-root__fileinput"
        />
        <button
          disabled={!files}
          onClick={(e) => this.uploadFiles(e)}
          className="csvuploader-root__btn"
        >
          {uploading ? "Bezig met inladen..." : "CSV inlezen"}
        </button>
        {uploading && (
          <ScaleLoader size={45} color={"#16326e"} loading={uploading} />
        )}
        {csvLines && csvLines.length && (
          <p>CSV ingelezen. {csvLines.length} lijnen gevonden</p>
        )}
      </div>
    );
  }
}

export default CSVReader;
