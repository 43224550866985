export const getNestedPropsFromString = (string, obj, flat) => {
  var value = undefined;
  var level = 0;
  if (
    string &&
    string.length &&
    obj &&
    Object.getOwnPropertyNames(obj).length
  ) {
    string.split('.').forEach((dataEl, i) => {
      if (i === level) {
        if (i === 0) {
          if (obj[dataEl] !== undefined) {
            value = obj[dataEl];
            level++;
          }
        } else {
          if (value && value[dataEl] !== undefined) {
            value = value[dataEl];
            level++;
          }
        }
      }
    });
  }
  if (Array.isArray(value)) {
    value = value.join(', ');
  }
  if (
    flat &&
    typeof value !== 'string' &&
    typeof value !== 'number' &&
    typeof value !== 'boolean'
  ) {
    value = null;
  }

  return value;
};

export const removePropFromObject = (obj, prop) => {
  const { [prop]: remove, ...rest } = obj;
  return rest;
};

export const removeFiltersWithProp = (obj, prop) => {
  let newObj = {};
  let currentObjectProps = Object.getOwnPropertyNames(obj || {});
  if (!currentObjectProps?.length) {
    return newObj;
  }
  for (let ac = 0; ac < currentObjectProps.length; ac++) {
    const theProp = currentObjectProps[ac];
    if (theProp.indexOf(prop) !== 0) {
      newObj[theProp] = obj[theProp];
    }
  }
  return newObj;
};

export const updateFiltersWithProp = (obj, prop, value) => {
  let newObj = {};
  let currentObjectProps = Object.getOwnPropertyNames(obj || {});
  let found = false;
  for (let ac = 0; ac < currentObjectProps.length; ac++) {
    const theProp = currentObjectProps[ac];
    if (theProp.indexOf(prop) === 0) {
      newObj[prop] = value;
      found = true;
    } else {
      newObj[theProp] = obj[theProp];
    }
  }
  if (!found) {
    newObj[prop] = value;
  }
  return newObj;
};
